import React from 'react';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import ToggleButtonGroup, { ToggleButtonGroupProps } from 'components/ui-components-v2/ToggleGroup';

interface Props {
    value: string;
    options: { [key: string]: string } | { key: string; value: string }[] | string[];
    readOnly?: boolean;
    onMutation: (value: any) => void;
    fullWidth?: ToggleButtonGroupProps['fullWidth'];
    size?: ToggleButtonGroupProps['size'];
}

/**
 * ButtonGroup
 * Displays a button group where you can switch between different fields.
 */
const ButtonGroupInput = ({ value, options, readOnly, onMutation, fullWidth = true, size = 'medium' }: Props) => {
    /**
     * Makes sure all options are formatted the same way
     * @returns Formatted options
     */
    const getOptions = (): { key: string; value: string }[] => {
        const newOptions = options;

        let formattedOptions: { key: string; value: string }[] = [];
        if (newOptions instanceof Array) {
            if (typeof newOptions[0] === 'string') {
                newOptions.forEach((option) => {
                    formattedOptions.push({ key: option, value: option });
                });
            } else {
                newOptions.forEach((option) => {
                    formattedOptions.push(option);
                });
            }
        } else {
            formattedOptions = Object.keys(newOptions).map((key) => ({ key, value: newOptions[key] }));
        }

        return formattedOptions;
    };

    const formattedOptions = getOptions();

    return (
        <ToggleButtonGroup value={value} fullWidth={fullWidth} size={size} disabled={readOnly}>
            {formattedOptions.map(({ key, value: label }) => (
                <ToggleButton color="primary" value={key} key={key} onClick={() => onMutation(key)}>
                    {label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default ButtonGroupInput;
